<!-- 编辑团队或者个人的timesheet 组件 -->
<template>
	<div class="d-flex" v-if="_worker">
		<div class="worker-profile">
			<v-avatar
				color="primary"
				size="48"
			>
			<span class="white--text text-h6">{{ _worker.user.name[0] }}</span>
			</v-avatar>
			<div class="font-weight-bold mt-2">{{ _worker.user.name }}</div>
			<div class="caption">{{ _worker.user.role }}</div>
		</div>
		<div class="worker-timesheet flex-grow-1 pb-12">	
			<!--- Clock in Clock out history -->
			<v-card outlined v-for="(timesheet, i) in _worker.clock_in_outs" :key="i" class="pa-2 text-right  ml-4 mr-14 mb-6">
				<div class="d-flex justify-space-between"><span>Clock In:</span><strong>{{ timesheet.clock_in }} </strong></div>
				<div class="d-flex justify-space-between"><span>Clock Out:</span> <strong>{{ timesheet.clock_out }} </strong></div>
				<v-divider class="my-2"></v-divider>
				<div class="amber darken-3 text-center pa-2 mb-3" dense dark rounded  v-if="getAssignedTimesheetHours  > calculateHourDifference(timesheet.clock_in, timesheet.clock_out)">
					<div class="white--text caption"><v-icon small class="white--text mr-2">mdi-alert</v-icon>注意！实际记录的工时大于工人打卡工时</div>
				</div>
				<div class="d-flex justify-space-between"><span>记录工时:</span><strong>{{ calculateHourDifference(timesheet.clock_in, timesheet.clock_out) }} 小时</strong> </div>
				<div class="d-flex justify-space-between"><span>实际工时:</span><strong>{{ getAssignedTimesheetHours }} 小时</strong></div>
			</v-card>
			<div class="d-flex align-start mb-6 px-4"  v-for="(activity, index) in _worker.activities" :key="activity.id">
					<div class="d-flex flex-column flex-grow-1">
						<div class="d-flex align-center py-2">
							<v-select
								v-model="activity.work_type.id"
								dense
								outlined
								hide-details
								:items="workTypes"
								item-text="name"
								item-value="id"
								label="工作类别"
								class="mr-3"
							>
							</v-select>

							<v-text-field
								v-model="activity.duration"
								dense
								outlined
								type="number"
								hide-details
								label="工时(小时)"
								required
							></v-text-field>
						</div>
						<div class="d-flex py-2">
							<v-textarea
								v-model="activity.notes"
								dense
								auto-grow
								rows="4"
								row-height="15"
								outlined
								hide-details
								label="工作记录">
							</v-textarea>
						</div>
					</div>
					<v-btn
						v-if="isRecordEditable"
						class="ml-2"
						dark
						color="error"
						icon
						@click="popDeleteConfirmation(activity.id, index)">
						<v-icon small>mdi-trash-can-outline</v-icon>
					</v-btn>
			</div>
			<!--- When remove remove timesheet record confirmation --->
			<confirmation :message="message" :value="dialogWarnning" :type="'warn'" @on-cancel="dialogWarnning = false" @on-confirm="confirmActivityDeletion" />
			<div class="d-flex justify-center mb-2"><v-btn color="info" text @click="addNewRecord"><v-icon>mdi-plus</v-icon>添加新记录</v-btn></div>
		</div>
	</div>
</template>
 
<script>

import { parse, differenceInHours } from 'date-fns';
import TimesheetService from '../../services/Timesheet';
import confirmation from '../../components/common/confirmation.vue';
export default {
	name: 'workerTimesheetEntryCard',

	data: () => ({
		_worker: null,
		dlgConfirmation: false,
		deleteIndex: null,
		dialogWarnning: false,
		message: '',
		activityIdForDelete: null,
		activityIndexForDelete: null
	}),
	components: {
		confirmation,
		TimesheetService
	},
	props: {
		worker: {
			type: Object,
			required: true
		},
		workTypes: {
			type: Array,
			required: true
		},
		isRecordEditable: {
			type: Boolean,
			default: true
		}
	},
	created(){
		this._worker = this.worker;
		this._worker.activities.forEach((record)=>{ 
			record.duration = (parseFloat(record.duration) / 60 ).toFixed(2)
		})

		this._worker.records = [
			{
				work_type_id: 1,
				hours: 0,
				notes: ''
			}
		];
	},
	computed:{
		getAssignedTimesheetHours(){
			let assignedHours = 0
			this._worker.activities.forEach((record)=>{
				assignedHours = assignedHours + parseFloat(record.duration)
			})
			return assignedHours? assignedHours : 0;
		}
	},
	watch: {
	},
	methods: {
		popDeleteConfirmation(activityId, currentIndex){
			if(activityId !== undefined){
				this.activityIdForDelete = activityId;
			} else {
				this.activityIndexForDelete = currentIndex;
			}
			this.message = 	`确定要删除当前工作数据吗？`
			this.dialogWarnning = true;
		},
		confirmActivityDeletion(){
			if(this.activityIdForDelete){
				this.deleteActivity();
			} else {
				this._worker.activities.splice(this.activityIndexForDelete, 1)
				this.dialogWarnning = false;
			}
		},
		async deleteActivity() {
			try {
				let response = await TimesheetService.deleteActivity(this._worker.id, this.activityIdForDelete)
				if(response.data.statusCode === 200){
					const indexToDelete = this._worker.activities.findIndex((activity)=> activity.id === this.activityIdForDelete)
					this._worker.activities.splice(indexToDelete, 1)
					this.dialogWarnning = false;
				}
			}catch(error) {
				console.log(error)
			}
		},
		addNewRecord(){
			const newRecord = {
					duration: 0,
					notes: '',
					work_type: {
						archived: false,
						id: 4,
						name: "清洁"
					},
				}
			this._worker.activities.push(newRecord)
		},
		calculateHourDifference(dateString1, dateString2) {
			// Define the format of your input dates
			const dateFormat = "dd/MM/yyyy HH:mm:ss";

			// Parse the date strings into Date objects
			const date1 = parse(dateString1, dateFormat, new Date());
			const date2 = parse(dateString2, dateFormat, new Date());

			// Calculate the difference in hours
			const hourDifference = differenceInHours(date2, date1);

			return hourDifference ? hourDifference : 0;
		}
	}
}
 </script>
 
 <style>
	.worker-profile {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 12px;
		padding-left: 12px;
		padding-right: 8px;
	}
	.v-textarea textarea {
		font-size: 14px;
		line-height: 1.4;
		padding-top: 8px;
	}
	.v-input input {
		font-size: 14px;
		padding-top: 12px;
	}
	.v-select__selections {
		font-size: 14px;
		padding-top: 12px;
	}
 </style>